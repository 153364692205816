<template>
  <div class="all_main_wrap">
    <!-- 头部 -->
    <div class="all_header_box">
      <div class="sider_left">
        <!-- 面包屑 -->
        <span class="title">
          <router-link
            class="crumbs_item"
            tag="a"
            to="/admin/Examination/TopicManage"
            >题目管理 /
          </router-link>
          <span class="crumbs_item crumbs_last">{{
            detail ? "详情" : libraryId ? "编辑" : "新建"
          }}</span>
        </span>
      </div>
    </div>
    <a-form
      id="components-form-demo-validate-other"
      :form="form"
      v-bind="formItemLayout"
      @submit="handleSubmit"
    >
      <a-form-item label="题干">
        <a-textarea
          style="width: 100%"
          v-decorator="[
            'title',
            { rules: [{ required: true, message: '请输入题干' }] },
          ]"
          placeholder="请输入题干内容"
          :auto-size="{ minRows: 3, maxRows: 5 }"
        />
      </a-form-item>
      <a-form-item label="题目类型">
        <a-radio-group
          v-decorator="[
            'type',
            { rules: [{ required: true, message: '请选择题目类型' }] },
          ]"
        >
          <a-radio value="1"> 单选 </a-radio>
          <a-radio value="2"> 多选 </a-radio>
          <a-radio value="3"> 判断 </a-radio>
        </a-radio-group>
      </a-form-item>
      <a-form-item>
        <span class="ant-form-text" style="color: red; margin-left: 75px">
          注：若选择单选，在选项中仅可选择一个正确答案；若选择多选，可选择多个正确答案
        </span>
      </a-form-item>
      <a-form-item label="题目难度">
        <a-radio-group
          v-decorator="[
            'difficulty',
            { rules: [{ required: true, message: '请选择题目难度' }] },
          ]"
        >
          <a-radio value="1"> 简单 </a-radio>
          <a-radio value="2"> 一般 </a-radio>
          <a-radio value="3"> 困难 </a-radio>
        </a-radio-group>
      </a-form-item>
      <a-form-item label="选项" :required="true"> 
        <!-- class="optionItem" -->
        <div v-for="(item, index) in optionJson" :key="index">
          <a-input
            :placeholder="letters[index] + '选项内容'"
            v-model="item.name"
            style="width: 400px"
          />
          <a-checkbox-group style="margin-left: 20px" v-model="item.isCorrect">
            <a-row>
              <a-col>
                <a-checkbox value="1"> 正确 </a-checkbox>
              </a-col>
            </a-row>
          </a-checkbox-group>
          <!-- <span style="color:#3681f0;cursor:pointer;" @click="delOptionItem(index)">删除</span> -->
        </div>
        <a-button type="primary" @click="addOption">添加选项</a-button>
      </a-form-item>
      <a-form-item>
        <a-checkbox-group style="margin-left: 150px" v-decorator="['isFixed']">
          <a-row>
            <a-col>
              <a-checkbox value="1"> 可打乱选项顺序 </a-checkbox>
            </a-col>
          </a-row>
        </a-checkbox-group>
      </a-form-item>
      <a-form-item :label="libraryId?'变更章节':'关联章节'">
        <a-tree-select
          v-decorator="['sectionId']"
          show-search
          style="width: 100%"
          :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
          placeholder="请选择关联章节"
          :tree-data="courseTree"
          :replaceFields="{children:'childList', title:'sectionName', key:'sectionId', value: 'sectionId' }"
          allow-clear
          tree-default-expand-all
        >
        </a-tree-select>
      </a-form-item>
      <a-form-item label="解析">
        <a-textarea
          style="width: 100%"
          v-decorator="[
            'answer'
          ]"
          placeholder="请输入解析内容"
          :auto-size="{ minRows: 3, maxRows: 5 }"
        />
      </a-form-item>
      <a-form-item :wrapper-col="{ span: 12, offset: 6 }">
        <a-button class="all_boder_btn btn" type="primary" :loading="loading" html-type="submit"> 提交 </a-button>
        <a-button class="all_boder_btn btn" @click="$router.go(-1)"
          >取消</a-button
        >
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import HeaderBox from "@/components/HeaderBox.vue";
export default {
  // 可用组件的哈希表
  components: { HeaderBox },
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      loading: false,
      courseTree: null, // 章节列表
      detail: "",
      libraryId: "",
      formItemLayout: {
        labelCol: { span: 2 },
        wrapperCol: { span: 9 },
      },
      category: "", // 大类
      letters: ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"],
      optionJson: [
        {
          isCorrect: null,
          name: "",
          deleted: 0,
        },
        {
          isCorrect: null,
          name: "",
          deleted: 0,
        },
        {
          isCorrect: null,
          name: "",
          deleted: 0,
        },
        {
          isCorrect: null,
          name: "",
          deleted: 0,
        },
      ],
    };
  },
  // 事件处理器
  methods: {
    // // 删除选项
    // delOptionItem(e){
    //   this.optionJson.splice(e,1);
    // },
    // 添加选项
    addOption() {
      // console.log(this.optionJson);
      if(this.optionJson.length==26){
        return this.$message.error('打住打住')
      }
      this.optionJson.push({
        isCorrect: null,
        name: "",
        deleted: 0,
      });
    },
    // 编辑、新增
    handleSubmit(e) {
      let _this = this;
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          // 判断是否勾选了足够的正确答案
          let i = 0;
          let j = 0;
          // console.log(this.optionJson)
          this.optionJson.forEach((element) => {
            if(element.name){
              j++;
            }
            if (element.name && element.isCorrect != null && element.isCorrect.length == 1) {
              i++;
            }
          });
          if(j<2){
            this.$message.warning("最少两个选项");
            return;
          }
          if (this.form.getFieldsValue(["type"]).type == 3) {
            if(j != 2){
              return this.$message.warning("判断题只能有两个选项且只能有一个正确答案");
            }
          }
          if (this.form.getFieldsValue(["type"]).type != 2) {
            if (i < 1) {
              this.$message.warning("请勾选正确答案");
              return;
            }else if(i>1){
              this.$message.warning("正确答案最对勾选一个");
              return;
            }
          } else {
            if (i <= 1) {
              this.$message.warning("请勾选足够的正确答案");
              return;
            }
          }
          let arr = [];
          _this.loading = true;
          _this.optionJson.map((item, index) => {
            arr.push({
              optionId: item.optionId?item.optionId:'',
              name: item.name,
              deleted: item.optionId && !item.name?1:item.deleted,
              isCorrect: item.isCorrect && item.isCorrect.length ? 1 : 0,
            });
          });
          _this
            .$ajax({
              url: this.libraryId
                ? "/hxclass-management/exam/library/edit"
                : "/hxclass-management/exam/library/save",
              method: this.libraryId ? "put" : "post",
              params: {
                category: _this.category, // 大类 1.考试 2.模拟考试 3.小节自测
                courseId: _this.$route.query.courseId, // 课程id
                difficulty: values.difficulty, // 难度 1.简单 2.一般 3.困难
                isFixed: values.isFixed && values.isFixed.length ? 0 : 1, // 是否是固定顺序 1.是 0.否
                libraryId: this.libraryId ? this.libraryId : "", // 	主建id
                optionJson: JSON.stringify(arr), // 选项数据json，仅用于新增
                sectionId: values.sectionId, // 章节id
                title: values.title, // 标题
                answer: values.answer, // 解析
                type: values.type, // 类型 1.单选 2.多选 3.判断
              },
            })
            .then((res) => {
              if (res.code == 200 && res.success) {
                this.$message.success(res.message);
                this.$router.go(-1);
              }else{
                _this.loading = false;
              }
            });
        }
      });
    },
    normFile(e) {
      // console.log("Upload event:", e);
      if (Array.isArray(e)) {
        return e;
      }
      return e && e.fileList;
    },
    getDetail() {
      let _this = this;
      this.$ajax({
        url: "/hxclass-management/exam/library/get?libraryId=" + this.libraryId,
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.form.setFieldsValue({
            title: res.data.title,
            answer: res.data.answer,
            isFixed: res.data.isFixed == 1?[]:['1'],
            sectionId: res.data.sectionId,
            type: "" + res.data.type,
            difficulty: "" + res.data.difficulty,
          });
          let arr = [];
          res.data.optionList.map((item, index) => {
            arr.push({
              optionId: item.optionId,
              name: item.name,
              deleted: item.deleted,
              isCorrect: item.isCorrect == 1 ? ["" + item.isCorrect] : null,
            });
          });
          _this.optionJson = arr;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // /hxclass-management/course/tree?courseId=1
    getCourseTree(e){
      this.$ajax({
        url:'/hxclass-management/course/tree?courseId='+e,
        method:'get'
      }).then(res=>{
        if(res.code == 200 && res.success){
          this.courseTree = res.data;
        }else{
          this.$message.error(res.message);
        }
      })
    }
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, {});
  },
  // 生命周期-实例创建完成后调用
  created() {
    this.category = this.$route.query.category;
    if (this.$route.query.libraryId) {
      this.libraryId = this.$route.query.libraryId;
      this.getDetail();
    }
    if (this.$route.query.detail) {
      this.detail = this.$route.query.detail;
      this.getDetail();
    }
    if(this.$route.query.courseId){
      this.getCourseTree(this.$route.query.courseId);
    }
  },
  // 生命周期-实例挂载后调用
  mounted() {
    // console.log(this.form);
    // this.form.getFieldValue().title = 555555555555;
  },
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
/deep/.input {
  width: 100%;
  margin-top: 10px;
}
/deep/.ant-form {
  margin-top: 20px;
}
.optionItem::before {
  content: "*";
  position: relative;
  left: 0;
  top: 0;
  font-size: 18px;
  color: #f3875d;
}
.btn{
  margin-right: 26px;
}
</style>
