import { render, staticRenderFns } from "./addTestQuestions.vue?vue&type=template&id=19bd1dc9&scoped=true&"
import script from "./addTestQuestions.vue?vue&type=script&lang=js&"
export * from "./addTestQuestions.vue?vue&type=script&lang=js&"
import style0 from "./addTestQuestions.vue?vue&type=style&index=0&id=19bd1dc9&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19bd1dc9",
  null
  
)

export default component.exports